<template>
    <div class="container-table2">
        <div class="table-responsive">
            <table>
                <thead>
                    <tr>
                        <th v-for="column in columns" :key="column">{{ column }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in rows" :key="index" v-if="rows.length > 0">
                        <td v-for="column in columns" :key="column + index">
                            <a v-if="links && links[column]" :href="links[column](row[column])" class="link-table" target="_blank">{{ row[column] }}</a>
                            <template v-else>
                                {{ row[column] }}
                            </template>
                        </td>
                    </tr>
                    <tr style="border-width: 0;" v-if="!rows || rows.length == 0">
                        <td :colspan="columns.length" class="no-data">Não existem dados disponíveis.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "Table",
    props: ["filter", "columns", "rows", "links"],
};
</script>

<style scoped>
    .no-data {
        color: #4B5563;
        text-align: center;  
        padding: 44px 0px 44px 0px;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .container-table2 {
        width: 100%;
        max-width: 1700px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }
    .table-responsive {
        width: 100%;
        overflow-x: auto;
    }
    .link-table {
        color: var(--Default-primary-800, #0854C5);

        /* text-sm/leading-5/font-medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    th {
        background-color: #F3F4F6;
        color: #475467;
        font-size: 13px;
        line-height: 18px;
        font-family: Inter;
        font-weight: 500;
        text-align: left;
        white-space: normal;
    }
    th, td {
        padding: 8px 8px;
        border: none;
    }
    td {
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    tr:not(:last-child) {
        border-bottom: 1px solid #EAECF0;
    }
</style>
