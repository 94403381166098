<template>
    <div :class="`container-big-number ${border ? 'left-border' : ''} ${size ? size : ''}`">
        <div class="sub-container d-row">
            <slot name="title">
                <span class="title">{{ title }}</span>
            </slot>
            <slot name="tooltip"></slot>
        </div>
        <div class="sub-container">
            <h4 :class="`big-number m-0 p-0`" :style="`color: ${color ?? '#111827'}`">{{ value }}</h4>
            <span class="subtitle">{{ subtitle }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "BigNumber",
    props: ["title", "value", "subtitle", 'border', 'size', 'color'],
};
</script>

<style scoped>
.container-big-number {
    gap: 8px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    &.left-border {
        border-left: 1px solid #D1D5DB;
        padding-left: 24px;
    }

    &.lg {
        .big-number {
            font-size: 20px;
            line-height: 28px;
        }
    }

    &.md {
        .subtitle {
            font-size: 14px;
            line-height: 20px;
            color: #111827;
        }
    }
}
.sub-container {
    display: flex;
    gap: 4px;
    flex-direction: column;
    &.d-row {
        flex-direction: row;
        gap: 4px;
        align-items: center;
    }
}
.title {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6B7280;
}

.big-number {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    color: #111827;
}

.subtitle {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6B7280;
}
</style>