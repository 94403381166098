<template>
  <div :class="`container-rel ${fullWidth ? 'fullWidth' : ''} ${colClass} `">
    <div :class="`inner-container ${fullWidth ? 'fullWidth' : ''}`">
      <div :class="`container-title ${fullWidth ? 'fullWidth' : ''} ${subtitle ? '' : ''}`">
        <h6 class="title-rel m-0 p-0">{{ title }}</h6>
        <p v-if="subtitle" :class="'subtitle-rel m-0 p-0'">
          {{ subtitle }}
        </p>
      </div>
      <slot></slot>
    </div>
    <div class="see-more" v-if="link">
      <router-link :to="link" class="text-blue-500">
        Ver detalhes
        <svg xmlns="http://www.w3.org/2000/svg" width="21"
          height="20" viewBox="0 0 21 20" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.71967 5.21967C9.01256 4.92678 9.48744 4.92678 9.78033 5.21967L14.0303 9.46967C14.3232 9.76256 14.3232 10.2374 14.0303 10.5303L9.78033 14.7803C9.48744 15.0732 9.01256 15.0732 8.71967 14.7803C8.42678 14.4874 8.42678 14.0126 8.71967 13.7197L12.4393 10L8.71967 6.28033C8.42678 5.98744 8.42678 5.51256 8.71967 5.21967Z"
            fill="#0854C5" />
        </svg>
      </router-link>
    </div>
  </div>
</template>


<script>
export default {
  name: "Container",
  props: {
    link: String,
    subtitle: String,
    title: String,
    fullWidth: Boolean,
    cols: {
      type: Number,
      default: 12,
      validator: value => value >= 1 && value <= 12
    }
  },
  computed: {
    colClass() {
      return `colx-${this.cols}`;
    }
  }
}
</script>
<style scoped>
.see-more {
  display: flex;
  justify-content: flex-end;
  gap: 2px;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid #EAECF0;
  a {
    color: #0854C5;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-decoration: none;

    :hover {
      text-decoration: none;
    }
  }
}

.mb-24 {
  margin-bottom: 24px;
}

.inner-container {
  padding: 24px;

  &.fullWidth {
    padding: 0;
  }
}

.container-rel {
  border: 1px solid #D7DAE2;
  box-sizing: border-box;
  border-radius: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.fullWidth {
    padding: 0;
  }

  .container-title {
    gap: 4px;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    &.fullWidth {
      padding: 16px 24px;
    }
  }
}

.subtitle-rel {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6B7280;
}

.title-rel {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #111827;
}

.rowx {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  /* Espaçamento entre as colunas */
  margin-right: -12px;
  /* Compensa metade do gap */
  margin-left: -12px;
  /* Compensa metade do gap */
}

[class*="colx-"] {
  position: relative;
  flex-grow: 1;
  flex-basis: calc((100% / 12) - 24px);
  /* Ajusta a largura para incluir o gap */
  max-width: calc((100% / 12) - 24px);
  /* Ajusta a largura para incluir o gap */
  box-sizing: border-box;
  /* Inclui padding na largura total */
}

.colx-1 {
  flex: 0 0 calc((100% / 12 * 1) - 24px);
  max-width: calc((100% / 12 * 1) - 24px);
}

.colx-2 {
  flex: 0 0 calc((100% / 12 * 2) - 24px);
  max-width: calc((100% / 12 * 2) - 24px);
}

.colx-3 {
  flex: 0 0 calc((100% / 12 * 3) - 24px);
  max-width: calc((100% / 12 * 3) - 24px);
}

.colx-4 {
  flex: 0 0 calc((100% / 12 * 4) - 16px);
  max-width: calc((100% / 12 * 4) - 16px);
}

.colx-5 {
  flex: 0 0 calc((100% / 12 * 5) - 24px);
  max-width: calc((100% / 12 * 5) - 24px);
}

.colx-6 {
  flex: 0 0 calc((100% / 12 * 6) - 12px);
  max-width: calc((100% / 12 * 6) - 12px);
}

.colx-7 {
  flex: 0 0 calc((100% / 12 * 7) - 24px);
  max-width: calc((100% / 12 * 7) - 24px);
}

.colx-8 {
  flex: 0 0 calc((100% / 12 * 8) - 8px);
  max-width: calc((100% / 12 * 8) - 8px);
}

.colx-9 {
  flex: 0 0 calc((100% / 12 * 9) - 24px);
  max-width: calc((100% / 12 * 9) - 24px);
}

.colx-10 {
  flex: 0 0 calc((100% / 12 * 10) - 24px);
  max-width: calc((100% / 12 * 10) - 24px);
}

.colx-11 {
  flex: 0 0 calc((100% / 12 * 11) - 24px);
  max-width: calc((100% / 12 * 11) - 24px);
}

.colx-12 {
  flex: 0 0 calc((100% / 12 * 12));
  max-width: calc((100% / 12 * 12));
}
</style>
