<template>
  <BaseModal name="sales-export" size="lg" :title="$t('seller.sales.text_2613')" @shown="openModal">
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>{{ $t('seller.sales.text_2614') }}</p>
          </div>
        </div>
        <b-form novalidate>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="$t('seller.sales.text_2615')" label-for="method" class="form-relative">
                <a href="#" @click.prevent="selecionarTodos('dados')" class="btn-todos">{{ dados.length > 0 ?
                  $t('seller.sales.clear_filters') : $t('seller.sales.text_2616') }}</a>
                <multiselect v-model="dados" placeholder="Selecione" label="name" track-by="id" :options="dice_list"
                  :multiple="true" :limit="8" :limitText="limitText" :taggable="false" :clearOnSelect="false"
                  :closeOnSelect="false" :showLabels="false">
                  <template #option="props">
                    <div class="d-flex">
                      <div :class="dados.find((item) => item.id === props.option.id) ? 'checkbox-checked' : 'checkbox-unchecked'
                        ">
                        <svg class="svg" v-if="dados.find((item) => item.id === props.option.id)"
                          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10"
                          height="10">
                          <path
                            d="M9.27276 2.98824C9.57574 2.68525 9.57574 2.19402 9.27276 1.89104C8.96978 1.58806 8.47855 1.58806 8.17556 1.89104L3.60344 6.46316L1.82444 4.68416C1.52145 4.38118 1.03022 4.38118 0.727237 4.68416C0.424254 4.98714 0.424254 5.47838 0.727237 5.78136L3.05484 8.10896C3.35782 8.41194 3.84905 8.41194 4.15204 8.10896L9.27276 2.98824Z"
                            fill="#fff" stroke-width="1"></path>
                        </svg>
                      </div>
                      <span class="span-label">
                        {{ props.option.name }}
                      </span>
                    </div>
                  </template>
                  <span slot="noResult">{{ $t('seller.sales.text_2620') }}</span>
                  <div slot="limit" class="multiselect__tag limit">
                    + {{ dados.length - 8 }}
                  </div>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="advanced_list.length">
            <b-col cols="12">
              <b-form-group :label="$t('seller.sales.text_2621')" label-for="method" class="form-relative">
                <a href="#" @click.prevent="selecionarTodos('advanced')" class="btn-todos">{{ dados2.length > 0 ?
                  $t('seller.sales.clear_filters') : $t('seller.sales.text_2616') }}</a>
                <multiselect v-model="dados2" placeholder="Selecione" label="meta_key" track-by="meta_key"
                  :options="advanced_list" :multiple="true" :limit="8" :limitText="limitText" :taggable="false"
                  :showLabels="false" :clearOnSelect="false" :closeOnSelect="false">
                  <template #option="props">
                    <div class="d-flex">
                      <div :class="dados2.find((item) => item.meta_key === props.option.meta_key) ? 'checkbox-checked' : 'checkbox-unchecked'
                        ">
                        <svg class="svg" v-if="dados2.find((item) => item.meta_key === props.option.meta_key)"
                          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="10"
                          height="10">
                          <path
                            d="M9.27276 2.98824C9.57574 2.68525 9.57574 2.19402 9.27276 1.89104C8.96978 1.58806 8.47855 1.58806 8.17556 1.89104L3.60344 6.46316L1.82444 4.68416C1.52145 4.38118 1.03022 4.38118 0.727237 4.68416C0.424254 4.98714 0.424254 5.47838 0.727237 5.78136L3.05484 8.10896C3.35782 8.41194 3.84905 8.41194 4.15204 8.10896L9.27276 2.98824Z"
                            fill="#fff" stroke-width="1"></path>
                        </svg>
                      </div>
                      <span class="span-label">
                        {{ props.option.meta_key }}
                      </span>
                    </div>
                  </template>
                  <span slot="noResult">{{ $t('seller.sales.text_2620') }}</span>
                  <div slot="limit" class="multiselect__tag limit">
                    + {{ dados2.length - 8 }}
                  </div>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col>
              <b-form-checkbox name="extra_charges" :value="true" class="form-relative" v-model="export_charges">
                {{ $t('seller.sales.export_next_charges') }}
                <p class="export-charges-description">{{ $t('seller.sales.export_next_charges_description') }}</p>
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group :label="$t('seller.sales.text_2622')">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text class="input-icon">
                      <font-awesome-icon icon="envelope" class="icon-gray" />
                    </b-input-group-text>
                  </template>
                  <b-form-input v-model="email" type="email" class="email-input"
                    placeholder="user@voomp.com.br"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <span class="max-days-export-text">O período máximo de exportação é de 90 dias.</span>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        {{ $t('seller.sales.text_2624') }}
      </BaseButton>
      <BaseButton variant="black" @click="onSubmit" :disabled="loading">
        {{ $t('seller.sales.text_2625') }}
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";

import SaleService from "@/services/resources/SaleService";
const serviceSale = SaleService.build();

export default {
  props: ["filters"],
  components: {
    Multiselect,
  },
  data() {
    return {
      email: "",
      loading: false,
      dados: [],
      dados2: [],
      export_charges: false,
      advanced_list: [],
      dice_list: [],
    };
  },
  methods: {
    limitText(count) {
      return `+ ${count}`
    },
    selecionarTodos(type) {
      switch (type) {
        case "dados": {
          this.dados = this.dados.length > 0 ? [] : this.dice_list;
          break;
        }
        case "advanced": {
          this.dados2 = this.dados2.length > 0 ? [] : this.advanced_list;
          break;
        }
      }
    },
    onSubmit() {
      this.loading = true;

      let basic = [];
      let dados = this.dados;
      for (let i = 0; i < dados.length; i++) {
        basic.push(dados[i].id);
      }
      let advanced = [];
      let dados2 = this.dados2;
      for (let i = 0; i < dados2.length; i++) {
        advanced.push(dados2[i].meta_key);
      }

      let data = {
        id: "/export",
        basic: basic,
        export_charges: this.export_charges,
        metas: advanced,
        email: this.email,
        filters: JSON.stringify(this.filters),
      };

      serviceSale
        .createId(data)
        .then((res) => {
          this.$bvToast.toast(
            res.message ? this.$t('seller.sales.wait_for_running_export_to_finish') : this.$t('seller.sales.text_2626'),
            {
              title: this.$t('seller.sales.text_2613'),
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.$bvModal.hide("sales-export");
        })
        .catch((err) => {
          console.log(err);
          this.$bvToast.toast(this.$t('seller.sales.text_2831'), {
            title: this.$t('seller.sales.text_2613'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("sales-export");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchSelectList() {
      this.loading = true;
      serviceSale
        .read("/export/select-list")
        .then((response) => {
          this.dice_list = response;
        })
        .catch((err) => {
          console.log(err);
          this.dice_list = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchAdicionais() {
      this.loading = true;
      serviceSale
        .read("/meta/keys")
        .then((response) => {
          this.advanced_list = response.filter(Boolean).filter((item) => {
            return !item.meta_key.includes("_id") && item.meta_key.length > 0;
          });
        })
        .catch((err) => {
          console.log(err);
          this.products = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openModal() {
      this.dados = [];
      this.dados2 = [];
      this.email = this.$store.getters.getProfile.email;
      this.fetchAdicionais();
      this.fetchSelectList();
    },
  },
  mounted() { },
};
</script>

<style scoped>
.email-input {
  border-left-width: 0;
  margin: 0;
  border-radius: 0 3px 3px 0 !important;
}

.email-input:hover,
.email-input:focus {
  border-color: #ededf0 !important;
}

.input-icon {
  background-color: #fff;
  border: 1px solid #ededf0;
  border-right: 0;
  margin: 0;
  padding-right: 1px;
  border-radius: 3px 0 0 3px;
  color: #495057;
}

.checkbox-checked {
  width: 20px;
  height: 20px;
  background-color: #2A63AB;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  svg {
    fill: #fff;
    stroke: #fff;
    color: #fff;
  }
}

.checkbox-unchecked {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #2A63AB;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.span-label {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.checkbox {
  display: flex;
  align-items: center;

}

.multiselect__tag {
  &.limit {
    background-color: #2A63AB;
    color: #fff;
    margin: 0;
    padding: 0.25rem 1rem 0.25rem 0.625rem;
    display: inline-block;
  }
}

p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}

p+p {
  margin-top: 5px;
}

.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }

  .top-dados #limpar {
    margin-top: 15px;
  }
}

.form-relative {
  position: relative;
}

.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
  text-decoration: underline;
}
.max-days-export-text {
    color: #666;
    margin-top: 5px;
    display: block;
}
</style>
