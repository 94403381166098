<template>
    <div class="info-tooltip"> 
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" v-b-tooltip.hover :title="label">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4001 8.00001C14.4001 11.5346 11.5347 14.4 8.0001 14.4C4.46548 14.4 1.6001 11.5346 1.6001 8.00001C1.6001 4.46538 4.46548 1.60001 8.0001 1.60001C11.5347 1.60001 14.4001 4.46538 14.4001 8.00001ZM8.8001 4.80001C8.8001 5.24183 8.44192 5.60001 8.0001 5.60001C7.55827 5.60001 7.2001 5.24183 7.2001 4.80001C7.2001 4.35818 7.55827 4.00001 8.0001 4.00001C8.44192 4.00001 8.8001 4.35818 8.8001 4.80001ZM7.2001 7.20001C6.86873 7.20001 6.6001 7.46864 6.6001 7.80001C6.6001 8.13138 6.86873 8.40001 7.2001 8.40001H7.4028C7.53075 8.40001 7.6258 8.51849 7.59804 8.64339L7.23073 10.2963C7.03644 11.1706 7.70174 12 8.59739 12H8.8001C9.13147 12 9.4001 11.7314 9.4001 11.4C9.4001 11.0686 9.13147 10.8 8.8001 10.8H8.59739C8.46944 10.8 8.3744 10.6815 8.40215 10.5566L8.76947 8.90371C8.96376 8.02939 8.29845 7.20001 7.4028 7.20001H7.2001Z" fill="#9CA3AF"/>
        </svg>
    </div>
</template>

<script>
export default {
    name: "InfoTooltip",
    props: ["label"],
};
</script>

<style scoped>
.info-tooltip {
    align-items: center;
    cursor: pointer;
}
</style>