<script>
import { Doughnut } from 'vue-chartjs';
Chart.pluginService.register({
  afterDraw(chart, args, options) {
    const {datasets} = chart.data;
    const {color, width, radiusDecrease} = options;
    let hasData = false;
   
    for (let i = 0; i < datasets.length; i += 1) {
      const dataset = datasets[i];
      hasData |= dataset.data.length > 0 && dataset.data.reduce((acc, value) => acc + value, 0) > 0;
    }
    if (!hasData) {
      const {chartArea: {left, top, right, bottom}, ctx} = chart;
      
      const centerX = (left + right) / 2;
      const centerY = (top + bottom) / 2;
      const r = Math.min(right - left, bottom - top) / 2;

      ctx.beginPath();
      ctx.lineWidth =  16;
      ctx.strokeStyle =  '#E5E7EB';
      ctx.arc(centerX, centerY, (75), 0, 2 * Math.PI);
      ctx.stroke();
    }
  },
  beforeDraw: function (chart) {
    var width = chart.chart.width,
      height = chart.chart.height,
      ctx = chart.chart.ctx;

    ctx.restore();

    if(!chart.data.datasets[0].subtext) return;
    
    ctx.font = "600 30px sans-serif";
    ctx.textBaseline = "middle";

    var text = chart.data.datasets[0].data.reduce((acc, value) => acc + value, 0),
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2 - 8;
    
    ctx.fillStyle  = '#111827'
    ctx.fillText(text, textX, textY);

    ctx.font = "400 14px sans-serif";
    ctx.fillStyle  = '#6B7280'
    var subtext = chart.data.datasets[0].subtext,
      subtextX = Math.round((width - ctx.measureText(subtext).width) / 2),
      subtextY = height / 2 + 12;

    ctx.fillText(subtext, subtextX, subtextY);
    ctx.save();
  }
});

export default {
  extends: Doughnut,
  props: ['generateLabels', 'data', 'labels', 'subtitle'],
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: [{
          data: this.data,
          subtext: this.subtitle,
          backgroundColor: [
            '#0854C5',
            '#1EA1FF',
            '#FF8200',
            '#FFB332',
            '#FFD966',
            '#FFEC99',
            '#FFF7CC',
          ],
          hoverOffset: 4
        }],
      },
      chartOptions: {
        aspectRatio: 1,
        legend: {
          display: false,
        },
        responsive: true,
        cutoutPercentage: 80,
      }
    }
  },
  computed: {
    label_data() {
      return this.generateLabels(this.$data._chart);
    },
    bigNumber() {
      return this.data.reduce((acc, value) => acc + value, 0);
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.chartOptions);
    this.label_data
  },
  watch: {
    data() {
      this.$data._chart.destroy();
      this.renderChart(this.chartData, this.chartOptions);
      this.label_data
    }
  }
}
</script>