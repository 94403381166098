<template>
	<div class="d-flex align-items-center flex-acoes">
		<div class="col-5 p-0">
			<multiselect v-model="productFilter" id="ajax" label="name" track-by="id"
				:placeholder="$t('views.seller.reports.text_1430')" selectLabel="" deselectLabel="" selectedLabel="✔️"
				open-direction="bottom" :options="products" :multiple="true" :searchable="true" :loading="loading"
				:internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="10"
				:show-no-results="true" :hide-selected="true" @search-change="aux_product_debounce">
				<span slot="noOptions">{{
					$t("views.seller.reports.text_1330")
				}}</span>
				<span slot="noResult">
					{{ $t("views.seller.reports.text_1331") }}
				</span>
			</multiselect>
		</div>
		<div class="ml-4 datas">
			<date-range-picker ref="picker" opens="right" :locale-data="localeDate" :showWeekNumbers="false"
				:showDropdowns="true" :autoApply="false" :ranges="dateRanges" @update="dateRanger" v-model="dateRange">
				<template v-if="dateRange.startDate && dateRange.endDate" v-slot:input="picker"
					style="min-width: 350px">
					{{ picker.startDate | date }} - {{ picker.endDate | date }}
				</template>
				<template v-else v-slot:input class="btn-table" :title="$t('views.seller.reports.text_1429')">
					{{ $t("views.seller.reports.text_1429") }}
				</template>
			</date-range-picker>
		</div>
		<div class="flex-grow"></div>
		<b-button class="btn-export" variant="primary" @click="exportData">
			{{ $t('seller.sales.text_2625') }}
		</b-button>
		<ExportModal :filters="exportFilter" />
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ProductService from "@/services/resources/ProductService";
import DateRangePicker from "vue2-daterange-picker";
import Moment from "moment-timezone";
import ExportModal from '@/components/Seller/Sales/modals/export'

const serviceProduct = ProductService.build();
const moment = Moment;

export default {
	components: {
		Multiselect,
		DateRangePicker,
		ExportModal
	},
	data: () => ({
		products: [],
		search: "",
		loading: false,
		dateRange: {
			startDate: moment.tz("America/Sao_Paulo").subtract(7, "days").toDate(),
			endDate: moment.tz("America/Sao_Paulo").endOf("day").toDate(),
		},
		totalDiffDays: 1,
		productFilter: [],
		localeDate: {
			direction: "ltr",
			format: "dd/mm/yyyy",
			separator: " - ",
			applyLabel: "Aplicar",
			cancelLabel: "Cancelar",
			weekLabel: "W",
			customRangeLabel: "Custom Range",
			daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
			monthNames: [
				"Jan",
				"Fev",
				"Mar",
				"Abr",
				"Mai",
				"Jun",
				"Jul",
				"Ago",
				"Set",
				"Out",
				"Nov",
				"Dez",
			],
			firstDay: 0,
		}
	}),
	watch: {
		productFilter: function (products) {
			this.$store.commit("setFilters", {
				products: products.map((item) => item.id),
			});
		},
		filter: {
			handler: function () {
				this.$parent.fetchReport();
			},
			deep: true,
		},
	},
	computed: {
		filter() {
			return this.$store.getters.getFilters;
		},
		exportFilter() {
			return {
				page: 1,
				per_page: 500,
				type: 'created_at',
				status: ["processing", "authorized", "paid", "refunded", "waiting_payment", "refund_pending", "refused", "chargedback", "delayed"],
				date_start: this.filter.startDate,
				date_end: this.filter.endDate,
				method: ["CREDIT_CARD", "PIX", "BOLETO", "PAYPAL"],
				salesOrigin: 0,
			}
		},
		dateRanges() {
			let today = new Date();
			today.setHours(0, 0, 0, 0);

			let yesterday = new Date();
			yesterday.setDate(today.getDate() - 1);
			yesterday.setHours(0, 0, 0, 0);

			let lastWeek = new Date();
			lastWeek.setDate(today.getDate() - 7);
			lastWeek.setHours(0, 0, 0, 0);

			return {
				[this.$t("locale_date.today")]: [today, today],
				[this.$t("locale_date.yesterday")]: [yesterday, yesterday],
				[this.$t("locale_date.last_week")]: [lastWeek, today],
				[this.$t("locale_date.current_month")]: [
					new Date(today.getFullYear(), today.getMonth(), 1),
					new Date(today.getFullYear(), today.getMonth(), 30),
				],
				[this.$t("locale_date.current_year")]: [
					new Date(today.getFullYear(), 0, 1),
					new Date(today.getFullYear(), 11, 31),
				],
				[this.$t("locale_date.last_month")]: [
					new Date(today.getFullYear(), today.getMonth() - 1, 1),
					new Date(today.getFullYear(), today.getMonth(), 0),
				],
				[this.$t("locale_date.every_period")]: [
					new Date(2020, 0, 1),
					new Date(today.getFullYear(), today.getMonth(), today.getDate()),
				],
			};
		},
	},
	methods: {
		dateRanger(dateRange) {
			let end = moment(dateRange.endDate);
			let start = moment(dateRange.startDate);

			this.totalDiffDays = end.diff(start, "days") + 1;

			let startDate = moment
				.tz(dateRange.startDate, "America/Sao_Paulo")
				.format("YYYY-MM-DDT00:00:00-03:00");
			let endDate = moment
				.tz(dateRange.endDate, "America/Sao_Paulo")
				.format("YYYY-MM-DDT23:59:59-03:00");
			this.$store.commit("setFilters", {
				startDate: startDate,
				endDate: endDate,
			});
			 
		},
		fetchProducts(search = null) {
			let data = {
				list: true,
				with_participants: true
			};
			if (search) data.search = search;
			this.products = [];
			serviceProduct
				.search(data)
				.then((response) => {
					for (let element of response) {
						if (this.products.length > 0 && this.products.find((item) => item.id === element.id)) {
							continue;
						}
						this.products.push({ id: element.id, name: element.name });
					}
				})
				.catch(() => {
					this.products = [];
				})
				.finally(() => {
					this.loading = false;
				});
		},
		aux_product_debounce(query) {
			this.loading = true;
			this.search = query;
			setTimeout(() => {
				this.fetchProducts(this.search);
			}, 500);
		},
		exportData() {
			this.$bvModal.show('sales-export')
		},
	},
	mounted() {
		let startDate = this.filter.startDate;
		let endDate = this.filter.endDate;		
	
		this.dateRange = {
			startDate,
			endDate,
		};

		let end = moment(this.dateRange.endDate);
		let start = moment(this.dateRange.startDate);
		this.totalDiffDays = end.diff(start, "days") + 1;

		this.$parent.fetchReport();
	},
};

</script>


<style scoped>
.flex-grow {
	flex-grow: 1;
}

.btn-export {
	background-color: #0854C5;
	font-weight: 600;
	font-size: 14px;
	font-family: Inter;
	line-height: 20px;
	height: 100%;
	padding: 0.8rem 1.8rem;
}
</style>